import React from 'react'
import styles from './ContentAdvice.module.scss'
import OverviewItem from './OverviewItem/OverviewItem'

import { string, array, bool } from 'prop-types'
import classNames from 'classnames'

const ContentAdvice = ({
  data,
  rating,
  showOnMobile,
  showDescription,
  hideDots,
  small
}) => {
  return (
    <div className={styles.Container}>
      <div
        className={classNames(
          styles.OverviewList,
          showOnMobile && styles.MobileOverviewList,
          showDescription && styles.OverViewList1Col
        )}
      >
        {data.map((item, index) => (
          <OverviewItem
            key={`content-advice-item--${index}`}
            {...item}
            rating={rating}
            showDescription={showDescription}
            hideDots={hideDots}
            small={small}
          />
        ))}
      </div>
    </div>
  )
}
ContentAdvice.propTypes = {
  data: array,
  rating: string,
  showOnMobile: bool,
  showDescription: bool,
  hideDots: bool,
  small: bool
}
export default ContentAdvice
